import React from "react"
import {
  Button,
  Card,
  Content,
  Layout,
  PositionCard,
  Share,
  Subscribe,
} from "../components"
import {Link} from "gatsby"
import SEO from "../components/SEO"
import {P} from "../elements"
import { ParseCareersHTML } from "../utils/ParseHTML"

const SingleCareerTemplate = ({ pageContext }) => {
  const { title, content, acf } = pageContext

  return (
    <Layout backgroundColor="gray" hasHeader={false}>
      <SEO title={title} description={content} />
      <Content>
        <PositionCard
          hideH2
          employmentType={acf.type_of_employment}
          location={acf.location}
          dueDate={acf.application_due_date}
          title={title}
        />
        <Share />
        <Card
          margin="50px 0 0 0"
          name="Position Details."
          title=""
          // title="It’s Pixel Perfect Limited is a digital media agency specialized in business transformation, market transformation, and experience transformation across multiple platforms."
        >
          {ParseCareersHTML(content)}
        </Card>
        <Button
          href={acf.cta_link}
          variant="solid"
          width="large"
          margin="50px 0"
          target="_blank"
        >
          Apply Now
        </Button>
      </Content>
      <Subscribe />
    </Layout>
  )
}
export default SingleCareerTemplate
